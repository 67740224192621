<nav  [ngClass]="{'navbar-top': showUpdateCard}" *ngIf="!showAndroidApp && !showIosApp" class="navbar navbar-default navbar-fixed-top nav-scroll" role="navigation">
    <div (mouseleave)="hoverOutClass()" class="header_section" id="header-section">
        <div id="header-pad" class="d-align-center">
            <div class="d-align-center" id="check">
                <div class="nav navbar-nav left-align">
                    <a class="home-logo" [ngClass]="baseService?.disableLogo ? 'cursor-default' : 'cursor-pointer'" (click)="redirectURL()"><img loading="lazy" [src]="logo"></a>
                </div>
                <ul class="nav navbar-nav left-align" id="listCategory">
                    <li *ngFor="let value of (categoryService.categories | async); let a=index" (mouseover)="hoverIn(value, a)" id="{{ 'object-' + a }}" class="category-list" >
                        <a (click)="redirect(value?.slug, value)" class="category" [ngClass]="{'active-category': value?.slug == selectedMenuItem}">{{value?.title}}
                        </a>
                        <img class="category-arrow-asset" *ngIf="value.slug === catName && categorySelected"  src="assets/{{appName}}/img/drop-up.svg">
                    </li>
                    <!-------------- my List------------->
                    <li (mouseover)="myListHover()" *ngIf="!(baseService.guest | async)" class="category-list">
                        <a [routerLink]="['/wishlist']" [queryParams]="{menu_item: 'wishlist'}" (click)="onWishlistClick('wishlist')" class="nav-no-border-bottom" [ngClass]="{'active-category': selectedMenuItem == 'wishlist'}">{{ configService.localeKeys?.menu_btn_wishlist_def}}</a>
                    </li>
                    <div class="container-fluid" [hidden]="!categorySelected" [ngClass]="{'category-hover':categorySelected==true}">
                        <div id="dropdown-menu-header" class="subcategory-list">
                            <div class="content">
                                <div class="row all-items">
                                    <ng-container *ngFor="let submenu of categoryInfo; let q = index">
                                        <div class="sub-menu-card" *ngIf="(submenu?.lists?.length)">
                                            <h6 *ngIf="submenu.display_title =='1'" (click)="redirectToViewAll(submenu)"class="sub-menu-header">{{ configService.localeKeys[submenu.submenu_translation_key] || submenu.submenu_title}} </h6>
                                            <div *ngIf="(submenu.submenu_content_row*submenu.submenu_content_col) < submenu.count ? submenu.submenu_content_col : ceilData(submenu.count/submenu.submenu_content_row); let col">
                                                <ul [ngStyle]="{'grid-template-rows': 'repeat(' + submenu.submenu_content_row + ', auto)' }" class="subcategory-list-items" *ngFor="let loop3 of counter(col);let i= index">
                                                    <ng-container *ngIf="submenu.submenu_content_row > submenu.count ? submenu.count : submenu.submenu_content_row; let row">
                                                        <ng-container *ngFor="let contents of counter(row);let j = index">
                                                            <li class="list-items-style" *ngIf="(((i * submenu.submenu_content_row) + j) < ((submenu.count > submenu.submenu_content_row*submenu.submenu_content_col) ? ((submenu.submenu_content_row*submenu.submenu_content_col)-1) : (submenu.submenu_content_row*submenu.submenu_content_col)) && (submenu.lists[(i*submenu.submenu_content_row)+j]?.title  || submenu.lists[(i*submenu.submenu_content_row)+j]?.collection_title))">
                                                                <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j]?.is_collection == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j], submenu)">{{submenu.lists[(i*submenu.submenu_content_row)+j]?.collection_title}}</a>
                                                                <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j]?.is_series == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j]?.title}} </a>
                                                                <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j]?.is_livechannel == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j]?.title}} </a>
                                                            </li>
                                                        </ng-container>
                                                    </ng-container>
                                                    <li *ngIf="submenu?.count > (submenu.submenu_content_row*submenu.submenu_content_col) && ((i+1)==submenu.submenu_content_col)"><a class="view-all-style" (click)="redirectToViewAll(submenu)"> {{ configService.localeKeys.homepg_menu_viewall || 'View all'}} </a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
            <ul class="nav navbar-nav navbar-right right-align d-align-center">
                <li>
                    <form id="form" method="post" name="searchForm">
                        <input #inputSearchElement id="inputSearch" (click)="inputClick($event)" class="search-field" (blur)="focusOutInput()" (focus)="focusInput()" placeholder="{{ configService.localeKeys?.what_to_watch }}" type="search" name="searchName" [(ngModel)]="searchName"
                            spellcheck="false" autocomplete="off">
                        <button id="searchIcon" class="search-box-xs searchButton" type="submit" (click)="searchShow($event)"><img loading="lazy" class="search-icon1" src="assets/{{appName}}/img/search.svg">
                        </button>
                        <button id="cancelIcon" (click)="closeSearch()" class=" search-box-xs searchButton"><img loading="lazy" class="search-icon1" src="assets/{{appName}}/img/close.svg">
                        </button>
                    </form>
                </li>
                <li *ngIf="!guest" class="dropdown nav-profile">
                    <a *ngIf="!guest" class="dropdown-toggle cursor-pointer" data-toggle="dropdown">
                        <div class="nav-icon-img">
                            <img loading="lazy" src="assets/{{appName}}/img/profile.svg">
                            <!-- <img loading="lazy" class="nav-profile-img bg-img" src="assets/{{appName}}/img/profile.svg"> -->
                        </div>
                        <!-- {{userName}} -->
                        <!-- <strong class="caret"></strong> -->
                    </a>
                    <ul class="dropdown-menu nav-profile-menu align-profile" (click)="closeSearch()">
                        <div class="border-bot more-profile">
                            <li>
                                <a [routerLink]="['/account/settings']">
                                    {{ configService.localeKeys?.menu_btn_my_acc_def}}
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/wishlist']">
                                    {{ configService.localeKeys?.menu_btn_my_list_def}}
                                </a>
                            </li>
                            <li>
                                <a [routerLink]="['/app/feedback']">
                                    {{ configService.localeKeys?.menu_btn_feed_def}}
                                </a>
                            </li>
                            <!-- <li>
                                <a (click)="redirect('/account/authenticate')" ui-sref="profile.authenticate({region: region})">
                                    {{ configService.localeKeys?.menu_btn_auth_def}}
                                </a>
                            </li> -->
                            <li *ngIf="settingsService.activateYourTvFromTVCode || settingsService.activateYourTvFromWebCode">
                                <a [routerLink]="['/account/activate-tv']">
                                    {{ configService.localeKeys?.activate_your_tv}}
                                </a>
                            </li>
                            <li>
                                <a class="cursor-pointer" (click)="logout()">
                                    {{ configService.localeKeys?.menu_btn_log_out_def}}
                                </a>
                            </li>
                        </div>
                    </ul>
                </li>
                <li *ngIf="guest">
                    <a *ngIf="operatorLogin!=0" [routerLink]="['/login/option']" class="login-btn">{{ configService.localeKeys?.login_title_log_in_def}}</a>
                    <a *ngIf="operatorLogin==0" [routerLink]="['/index/signin']" class="login-btn">{{ configService.localeKeys?.login_title_log_in_def}}</a>
                </li>
            </ul>
        </div>
    </div>
    <!-- <div class="container-fluid" height="50" [hidden]="!categorySelected" style="left: auto; right: 0; position: absolute"  [ngClass]="{'category-hover':categorySelected==true}">
        <div class="subcategory-list">
            <div class="container sub-container header-sub-cat-pad">
                <div (mouseover)="hoverIn('sub')" *ngFor="let submenu of categoryInfo" class="show-content-category">
                    <div>
                        <a class="sub-category-name" *ngIf="submenu.display_title =='1'" (click)="redirectGenre(submenu)">
                            {{ configService.localeKeys[submenu.submenu_translation_key]}}
                        </a>
                        <div *ngIf="submenu.lists.length > 0">
                            <ng-container *ngIf="submenu.submenu_content_row > submenu.count ? '1' : submenu.submenu_content_col; let col">
                                <ul class="menu-item-dropdown" *ngFor="let loop3 of counter(col);let i= index">
                                    <ng-container *ngIf="submenu.submenu_content_row > submenu.count ? submenu.count : submenu.submenu_content_row; let row">
                                        <ng-container *ngFor="let contents of counter(row);let j = index">
                                            <li class="list-items-style dropdown fill-width" *ngIf="(((i*submenu.submenu_content_row)+j) < ((submenu.submenu_content_row*submenu.submenu_content_col)-1) && (submenu.lists[(i*submenu.submenu_content_row)+j].title  || submenu.lists[(i*submenu.submenu_content_row)+j].collection_title))">
                                                <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j].is_collection == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])">{{submenu.lists[(i*submenu.submenu_content_row)+j].collection_title}}</a>
                                                <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j].is_series == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j].title}}</a>
                                                <a *ngIf="submenu.lists[(i*submenu.submenu_content_row)+j].is_livechannel == '1'" (click)="redirectSubmenu(submenu.lists[(i*submenu.submenu_content_row)+j])"> {{submenu.lists[(i*submenu.submenu_content_row)+j].title}}</a>
                                            </li>  
                                        </ng-container>
                                    </ng-container>
                                    <li *ngIf="submenu.lists.length > (submenu.submenu_content_row*submenu.submenu_content_col) && ((i+1)==submenu.submenu_content_col)"><a (click)="redirectGenre(submenu)"> View All</a></li>
                                </ul>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

</nav>
