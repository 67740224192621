import { Injectable } from '@angular/core';
import {
  contentVideoAsset, contentPersonDataVideo, similarAsset, userContinueWatch,
  assetContentSeason, assetContentSeries, userHistory, watchCount, contentPersonDataSeries, trailerInfo, api, contentPinValidation
} from 'src/app/shared/constants';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { ConfigurationService } from './configuration.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  contentSlug: any;
  seekValue: any;
  seriesSlug: any;
  seasonId: any;
  autoPlayVideo: boolean = false;
  play: boolean = false;
  start: boolean = false;
  trailer: any;
  epgRoute = false;
  livePlayerActive: boolean = false;
  languageChanged = new Subject()
  circularLoader = false;
  channelsStickTop = false;
  mouseWheelActivate = false;
  contentId:any;
  webSocketUrl;
  socket:WebSocket;
  activeRouteInterval;
  pingIntervalId: any;
  connectUrl: any;
  connectionTimeout: number = 2 * 60 * 60 * 1000;
  connectionTimer: any;
  public socketResponse = new BehaviorSubject(false);
  public concurrentErrorMsg =  new BehaviorSubject(false);
  public liveConcurrentErrorMsg =  new BehaviorSubject(false);
  constructor(private http: HttpClient, private uikitService: UikitService,
              private storageService: StorageService, private settingsService : SettingsService, private baseService: BaseService,
               private configService: ConfigurationService, private router: Router) {
                 this.connectUrl = '';
                this.webSocketUrl = this.settingsService.webSocketUrl;
                this.activeRouteInterval = this.settingsService.activeRouteInterval;
               }

  getCategoryDataById(id: string): Observable<any> {
    // this.settingsService.spinner = true;
    const contentURL = environment.apiUrl + environment.version + contentVideoAsset + id;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(contentURL, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data.content;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error.error && error.error.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }
  getContentPersonData(id: string, isPLayedFromplayer?: string) {
    let streamingTYpe = this.baseService.checkBrowser();
    const contentURL = environment.apiUrl + environment.version + contentPersonDataVideo + id;
    let params = new HttpParams()
      .set('d_type', 'web');
      params = params.append('streaming_type', streamingTYpe)
      params = params.append('supports_drm', this.settingsService.enableDrm)
    if(isPLayedFromplayer === '1')
      params = params.append('is_played_from_player', isPLayedFromplayer)
    return this.http.get(contentURL, { params }).pipe(
      map(
        (response: any) => {
            return response;
        }
        , (error: HttpErrorResponse) => {
          return error;
        }
      )
    );
  }

  getSimilarAssestById(id: string) {
    const similarAssetURL = environment.apiUrl + environment.version + similarAsset + id;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(similarAssetURL, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            return response.data;
          } else {
            // this.uikitService.notifyError(response)
            // $state.go('profile.404', {}, { reload: true });
          }
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
        }
      )
    );
  }

  changeSeasonApi(id: string) {
    const changeSeasonURL = environment.apiUrl + environment.version + assetContentSeason + id;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(changeSeasonURL, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            return response.data;
          } else {
            // this.uikitService.notifyError(response)
            // $state.go('profile.404', {}, { reload: true });
          }
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
        }
      )
    );
  }

  getEpisodesApi(id: string): Observable<any> {
    const getEpisodesUrl = environment.apiUrl + environment.version + assetContentSeries + id;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(getEpisodesUrl, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            return response.data;
          } else {
            // this.uikitService.notifyError(response)
            // $state.go('profile.404', {}, { reload: true });
          }
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
        }
      )
    );
  }

  watchCountApi(id: any, contentType: any) {
    const watchCountUrl = environment.apiUrl + environment.version + watchCount;
    const userId: string = this.storageService.getLocalStore('u_id');
    const body = {
      content_id: id,
      u_id: userId,
      content_type: contentType,
      d_type: 'web'
    };
    return this.http.put(watchCountUrl, body).pipe(
      map((response: any) => {
      }), catchError((error) => {
        if (error.error && error.error.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  userHistoryApi(contentId: any) {
    const userHistoryUrl = environment.apiUrl + environment.version + userHistory;
    const userId: string = this.storageService.getLocalStore('u_id');
    const body = {
      content_id: contentId,
      u_id: userId,
      d_type: 'web'
    };
    return this.http.post(userHistoryUrl, body).pipe(
      map((response) => {
        return response;
      }), catchError((error) => {
        if (error.error && error.error.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  userContinueWatchingApi(data) {
    const userContinueWatchingUrl = environment.apiUrl + environment.version + userContinueWatch;
    return this.http.post(userContinueWatchingUrl, data).pipe(
      map((response) => {
        return response;
      }), catchError((error) => {
        if (error.error && error.error.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  getSeriesPersonData(id: string) {
    const contentURL = environment.apiUrl + environment.version + contentPersonDataSeries + id;
    const params = new HttpParams()
      .set('d_type', 'web');
    return new Promise((resolve, reject) => {
      return this.http.get(contentURL, { params }).subscribe(
        (response: any) => {
          resolve(response);
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
          resolve(error);
        }
      );

    });
  }

  getTrailer(id) {
    const contentURL = environment.apiUrl + environment.version + trailerInfo + id;
    const params = new HttpParams()
      .set('d_type', 'web')
      .set('region', 'int')
    return new Promise((resolve, reject) => {
      return this.http.get(contentURL, { params }).subscribe(
        (response: any) => {
          resolve(response);
        }
        , (error: HttpErrorResponse) => {
          // this.uikitService.notifyError(response)
          // $state.go('profile.404', {}, { reload: true });
          resolve(error);
        }
      );

    });
  }

  validateParentalPin(data){
    const validatePinURL = environment.apiUrl + environment.version + contentPinValidation;
    return new Promise((resolve) => {
      return this.http.post(validatePinURL, data).subscribe(
        (response: any) => resolve(response),
        (error) => resolve(error)
      );
    });
  }

  getPlayerOptions() {
    var playerOptionUrl = this.settingsService.player_language_options;
    return this.http.get(playerOptionUrl)
      .pipe(
        map(
          (response: any) => {
            return response;
          }), catchError((error) => {
            if (error.error && error.error.code !== 1002) {
              this.uikitService.notifyError(error);
              //this.router.navigateByUrl('404');
            }
            return error;
          })
      );
  }
  async translateSelectedLocale(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let selectedLocale;
      this.getPlayerOptions().subscribe(async (data) => {
        if (data) {
          const playerOptionsText = data;
          selectedLocale =  this.configService.localeKeys?.hasOwnProperty((playerOptionsText[this.configService?.locales[this.configService?.localeIndex || 0].lang_code.trim().split('-')[0]]?.iso_language_name).toLowerCase()) ? this.configService.localeKeys[(playerOptionsText[this.configService?.locales[this.configService.localeIndex].lang_code.trim().split('-')[0]].iso_language_name).toLowerCase()] : (this.configService.localeKeys[this.configService.locales[this.configService.localeIndex].lang_name] || 'Select Locale');
          resolve(selectedLocale);
        } else {
          reject('No data available');
        }
      });
    });
  }
  translateLanguage(locales) {
    this.getPlayerOptions().subscribe(async (playerOptionsText) => {
      if (playerOptionsText) {
        locales.map(locale => {
          if (playerOptionsText.hasOwnProperty(locale.lang_code.trim().split('-')[0])) {
            locale.translatedLanguage = this.configService.localeKeys.hasOwnProperty(playerOptionsText[locale.lang_code.trim().split('-')[0]].iso_language_name.toLowerCase()) ? this.configService.localeKeys[playerOptionsText[locale.lang_code.trim().split('-')[0]].iso_language_name.toLowerCase()] : (playerOptionsText[locale.lang_code.trim().split('-')[0]].iso_language_name);
          } else {
            locale.translatedLanguage = locale.lang_code.trim().split('-')[0];
          }
          return locale;
        });
      }
    });
    return locales;

  }
  establishWebSocketConnection(): void {
    this.connectUrl = `${this.webSocketUrl}?Auth=Bearer${this.storageService.getLocalStore('a_t')}`;
    this.socket = new WebSocket(this.connectUrl);
    this.socket.onopen = () => {
      // Connection opened - send initial data
      if (this.socket.readyState === WebSocket.OPEN) {
        const initialData = {
          action: 'update_data',
          data: {
            user_id: this.storageService.getLocalStore('u_id'),
            device_id: this.storageService.getLocalStore('d_id'),
            content_id: this.contentId,
          },
        };
        this.socket.send(JSON.stringify(initialData));
      }
      // Start sending "PING" messages at regular intervals
      this.pingIntervalId = setInterval(() => {
        const intervalMsg = {
          "action": "ping"
        }
        this.socket.send(JSON.stringify(intervalMsg));
      }, (this.activeRouteInterval * 1000));
       // Set the connection timeout
      this.connectionTimer = setTimeout(() => {
        this.closeWebSocketConnection();
        this.establishWebSocketConnection(); // Open a new connection
    }, this.connectionTimeout);
    };
    this.socket.onmessage = (event) => {
      // Handle received messages here
      let message = JSON.parse(event.data);
     if(((message.status_code == 500) && (message.error.code == 2501))){
      this.socketResponse.next(true);
      this.concurrentErrorMsg.next(true);
      this.liveConcurrentErrorMsg.next(true);
    }
    if(((message.status_code == 500) && (message.error.code == 2500))){
      this.closeWebSocketConnection();
      this.router.navigateByUrl('/home');
    }
    };
    this.socket.onerror = (error) => {
      // Handle WebSocket errors here
      console.error('WebSocket error:', error);
    };
    this.socket.onclose = () => {
      // WebSocket connection closed - stop sending "PING" messages
      clearInterval(this.pingIntervalId);
      // Clear the connection timeout
      clearTimeout(this.connectionTimer);
    };
  }
  closeWebSocketConnection(): void {
    this.socketResponse.next(false);
    if (this.socket) {
      clearInterval(this.pingIntervalId);
      this.socket.close();
      this.socket = undefined;
      clearTimeout(this.connectionTimer);
    }
  }
}
