import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class LoginModeGuard implements CanActivate {
  constructor(private router: Router, private baseService: BaseService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.baseService.loginMode === 1) {
      this.router.navigateByUrl('/index/signup')
    } else return true;
  }
  
}
