import { Component, OnInit, Input, HostListener, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { BaseService, SettingsService, StorageService } from 'src/app/core/services';
import { NavigationExtras, Router } from '@angular/router';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { ContentService } from 'src/app/core/services/content.service';
import { HomeService } from 'src/app/core/services/home.service';
import { WishlistService } from 'src/app/core/services/wishlist.service';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { PlayContentService } from 'src/app/core/services/play-content.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-slick-item',
  templateUrl: './' + environment.htmlAppName + '/slick-item.component.html',
  styleUrls: ['./' + environment.appName + '/slick-item.component.scss']
})
export class SlickItemComponent implements OnInit, OnChanges, OnDestroy {

  imagePath;
  cardConfig;
  seriesConfig;
  liveConfig;
  slideToShow;
  slideToScroll;
  slideToShowSeries;
  slideToScrollSeries;
  value;
  windowWidth;
  barwidth;
  searchKey;
  searchActive: boolean;
  datas: any[];
  dataList;
  @Input() data;
  @Input() dataIndex;
  @Output() closeEvent = new EventEmitter<boolean>();
  @Input() categoryName;
  @Input() searchData;
  @Input() pageDetail;
  @Input() noViewMore;
  orientation: string;
  tags;
  tagsLength: number = 0;
  key;
  guest;
  enableRouting = true;
  liveSlidesToShow: number;
  liveSlideToScroll: number;
  takeValue: number;
  dataCount: any;
  groupCollectionSlidesToShow: number;
  groupCollectionSlidesToScroll: number;
  groupCollectionConfig;
  topInRegionConfig;
  topInRegionSlidesToShow: number;
  topInRegionSlidesToScroll: number;
  buttonClicked = false;
  cardHovered = false;
  preventClick = false;
  appName = 'filmbox';
  constructor(public settingsService: SettingsService, private router: Router, private storageService: StorageService,
              private datalayerService: DatalayerService, public contentService: ContentService,
              private homeService: HomeService, private wishListService: WishlistService,
              public configService: ConfigurationService,public baseService: BaseService,
              public playContentService: PlayContentService) {
    this.takeValue = this.settingsService.takeValue;
    this.imagePath = this.settingsService.imagePath;
    this.appName = environment.appName;
    this.getAdjustCards();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        if (this.data) {
          this.dataCount = this.data.count;
        }
        if (this.data.list) {
          this.dataList = this.data.list;
        } else if (this.data.contents) {
          this.dataList = this.data.contents;
        } else if (this.data.videos) {
          this.dataList = this.data.videos;
        }
        this.dataList = this.dataList.slice(0, (this.data.take_value || this.takeValue) + 1);
        this.key = this.data.component;
        this.orientation = this.data.orientation;
        this.dataList.map(data => {
          if (data.tags && typeof (data.tags) == 'string') {
            this.tagsLength = data.tags.length;
            return data.tags = data.tags.split(',');
          } else {
            return;
          }
        });
      }
    }
  }

  ngOnInit(): void {
    this.baseService.guest.subscribe(data => this.guest = data);
    this.homeService.contentWishlist.subscribe((data) => {
      if (this.dataList?.length && data?.length) {
        this.dataList.forEach(banner => {
          if (banner.is_series) {
            banner.wishlist = data.includes(banner.series_slug);
          } else {
            banner.wishlist = data.includes(banner.content_slug);
          }
        });
      } else {
        this.dataList.forEach(banner => {
          banner.wishlist = false;
        });
      }
      this.baseService.loaderSource.next(false);
    })
    setTimeout(()=>window.dispatchEvent(new Event('resize')),50);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getAdjustCards();
  }

  slickInit(event){
    const nextArrow = event?.slick?.$nextArrow;
    const prevArrow = event?.slick?.$prevArrow;
    prevArrow.attr('id','prev'+this.data.key);
    nextArrow.attr('id','next'+this.data.key);
    const prevButton = document.getElementById('prev'+this.data.key)
    prevButton.addEventListener('click', () => this.restrictHoverOnScroll(nextButton))
    const nextButton  = document.getElementById('next'+this.data.key);
    nextButton.addEventListener('click', () => this.restrictHoverOnScroll(nextButton))
  }

  restrictHoverOnScroll (btnElem) {
    const slickList = btnElem.previousElementSibling;
    const slickElements = slickList.querySelectorAll('.slick-slide');
    slickElements.forEach((elem)=>{
      elem.classList.add('no-pointer');
      setTimeout(() => elem.classList.remove('no-pointer'), 100);
    })
  }

  playVideo(value, status) {
    if (status == 'play') {
      this.contentService.play = true;
    } else {
      this.contentService.start = true;
    }
    this.addGTMData(value);
    if (value.is_livechannel) {
      this.baseService.storeEPGValue(value.slug);
    } else if (value.is_series) {
      const seriesSlug = value?.seasons_slug || value?.series_slug;
      const contentSlug = value?.content_slug;
      this.router.navigate(['/series', seriesSlug, contentSlug]);
    } else {
      this.router.navigate(['/video', value.collection_slug, value.content_slug]);
    }
  }

  playTrailer(value) {
    this.contentService.trailer = value;
    this.addGTMData(value);
    if (value.is_series) {
      const seriesSlug = value?.seasons_slug || value?.series_slug;
      const contentSlug = value?.content_slug;
      this.router.navigate(['/series', seriesSlug, contentSlug]);
    } else {
      this.router.navigate(['/video', value.collection_slug, value.content_slug]);
    }
  }

  getAdjustCards() {
    this.windowWidth = window.innerWidth;
    let paddingLeft = 130;
    if(this.windowWidth >= 992 && this.windowWidth <= 1199){
      paddingLeft = 90;
    } else if(this.windowWidth >= 768 && this.windowWidth < 992){
      paddingLeft = 50;
    }
    this.slideToShow = ((this.windowWidth - paddingLeft) / (283 + 20));
    this.slideToScroll = this.slideToShow;
    this.slideToShowSeries = ((this.windowWidth - paddingLeft) / (283 + 20));
    this.slideToScrollSeries = this.slideToShowSeries;
    this.liveSlidesToShow = ((this.windowWidth - paddingLeft)/ (587 + 20));
    this.liveSlideToScroll = this.liveSlidesToShow;
    this.groupCollectionSlidesToShow = ((this.windowWidth - paddingLeft) / (204 + 20));
    this.groupCollectionSlidesToScroll = this.groupCollectionSlidesToShow;
    this.topInRegionSlidesToShow = (this.windowWidth - paddingLeft) / (580 + 20);
    this.topInRegionSlidesToShow = (this.topInRegionSlidesToShow <1) ? 1: this.topInRegionSlidesToShow;
    this.topInRegionSlidesToScroll = this.topInRegionSlidesToShow;
    this.barwidth = 0.85;
    this.cardConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: (this.slideToShow),
      variableWidth: false,
      slidesToScroll: Math.floor(this.slideToShow)
    };
    this.seriesConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: (this.slideToShowSeries),
      variableWidth: false,
      slidesToScroll: Math.floor(this.slideToScrollSeries)
    };
    this.liveConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: this.liveSlidesToShow,
      variableWidth: false,
      slidesToScroll: Math.floor(this.liveSlideToScroll)
    }
    this.groupCollectionConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: this.groupCollectionSlidesToShow,
      variableWidth: false,
      slidesToScroll: Math.floor(this.groupCollectionSlidesToScroll)
    };
    this.topInRegionConfig = {
      centerMode: false,
      infinite: false,
      slidesToShow: this.topInRegionSlidesToShow,
      variableWidth: false,
      slidesToScroll: Math.floor(this.topInRegionSlidesToScroll)
    }
  }

  addGTMData(video) {
    if (this.categoryName) {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Content Click',
        eventAction: this.categoryName,
        eventLabel: video.title,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        contentId: video.contentId,
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        notificationStatus: localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
    else if (this.searchData) {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Search',
        eventAction: this.searchData,
        eventLabel: this.settingsService.virtualUrl,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        contentId: video.contentId,
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        notificationStatus: localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
    else {
      const datalayerData = {
        event: 'GAEvent',
        eventCategory: 'Content Click',
        eventAction: (video.collection_title) ? video.collection_title : video.seasons_title,
        eventLabel: video.title,
        contentId: video.contentId,
        loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
        userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
        notificationStatus: localStorage.getItem('emailNotify') ||  'False',
        region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
        content: this.datalayerService.getCategoryDatalayer(video, 'content'),
        VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
        platform: this.baseService.browserType,
        userType: this.storageService.getLocalStore('user_type'),
        operator: this.storageService.getLocalStore('operator_name')
      };
      this.datalayerService.addDatalayer(datalayerData);
    }
  }

  redirect(video, key, title) {
    if (this.enableRouting) {
      this.closeSearch();
      this.addGTMData(video);
      if (video.is_livechannel) {
        this.baseService.storeEPGValue(video.slug);
        if(this.settingsService.epgEnabled)this.contentService.autoPlayVideo = true;
        this.router.navigate(['/live', video.slug]);
      } else if (video.is_series) {
        const seriesSlug = video?.seasons_slug || video?.series_slug;
        const contentSlug = video?.content_slug;
        if (video.watched_duration) {
          this.contentService.contentSlug = contentSlug;
          this.contentService.seriesSlug = seriesSlug;
          this.contentService.seekValue = video.watched_duration;
        } else {
          this.contentService.seekValue = 0;
        }
        this.router.navigate(['/series', seriesSlug, contentSlug]);
      } else if (key === 'video' && this.pageDetail === 'live') {
        if(this.settingsService.epgEnabled)this.contentService.autoPlayVideo = true;
        this.router.navigate(['/live/player', 'video', video.content_id]);
      } else {
        this.router.navigate(['/video', video.collection_slug, video.content_slug]);
      }
    }
  }

  redirectToCollection(value, type, widgetData) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        submenu_title: widgetData?.title_translation_key,
        slug: widgetData?.slug
      }
    };
    this.router.navigate([`/${type}/${value.slug}`], navigationExtras);
  }

  closeSearch() {
    this.closeEvent.emit(true);
  }

  removeContinue(continueId: string) {
    this.preventClick = true;
    this.homeService.removeContinueWatch(continueId).subscribe((data) => {
      if (data) {
        this.preventClick = false;
        this.homeService.updateUserContentFlag = true;
        this.homeService.updateUserContent.next(true);
      }
    });
  }

  removeWishlist(content) {
    if (content.is_series) {
      const paramsData: object = {
        req: { id: content.content_id },
        query: { d_type: 'web', status }
      };
      this.wishListService.removeSeriesWishList(paramsData).subscribe((data) => {
        if (data) {
          this.homeService.updateUserContentFlag = true;
          this.homeService.updateUserContent.next(true);
        }
      });
    } else {
      const paramsData: object = {
        req: { content_type: 'video', id: content.content_id },
        query: { d_type: 'web' }
      };
      this.wishListService.removeWishlist(paramsData).subscribe((data) => {
        if (data) {
          this.homeService.updateUserContentFlag = true;
          this.homeService.updateUserContent.next(true);
        }
      });
    }
  }

  beforeChange($event) {
    this.enableRouting = false;
  }

  afterChange($event) {
    this.enableRouting = true;
  }
  mouseEnter() {
    let buttonDiv: HTMLElement = document.querySelector('#slick-'+ this.dataIndex);
    let nextButton: HTMLButtonElement = buttonDiv.querySelector('.slick-next');
    let prevButton: HTMLButtonElement = buttonDiv.querySelector('.slick-prev');
    nextButton.style.cssText += 'opacity: 0;'
    prevButton.style.cssText += 'opacity: 0;'
  }
  mouseLeave() {
    let buttonDiv: HTMLElement = document.querySelector('#slick-'+ this.dataIndex);
    let nextButton: HTMLButtonElement = buttonDiv.querySelector('.slick-next');
    let prevButton: HTMLButtonElement = buttonDiv.querySelector('.slick-prev');
    nextButton.style.cssText += 'opacity: 1;'
    prevButton.style.cssText += 'opacity: 1;'
  }

  viewMore(data) {
    if (data.component === 'group_collection_widget') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          category: false,
        }
      };
      this.router.navigate([`/movies/${data.slug}`], navigationExtras);
    } else if (data.component === 'continue_watching_widget') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          category: this.categoryName,
        }
      };
      this.router.navigate([`/list/${data.key}`], navigationExtras);
    } else if (data.component === 'my_list_widget') {
      const navigationExtras: NavigationExtras = {
        queryParams: {
          category: this.categoryName,
        }
      };
      this.router.navigate([`/${data.key}`], navigationExtras);
    } else if(data.component === 'livechannel_widget' && data?.list?.length) {
      let live_channel_slug = '';
      if(this.storageService.getLocalStore('epg_live_channel')) live_channel_slug = this.storageService.getLocalStore('epg_live_channel');
      else live_channel_slug = data?.list[0].slug;
      this.baseService.storeEPGValue(live_channel_slug);
      if(this.settingsService.epgEnabled)this.contentService.autoPlayVideo = true;
      this.router.navigate(['/live/', (live_channel_slug)]);
    } else {
      this.router.navigate([`/list/${data.slug}`]);
    }
  }

  hoverIn(dataKey, type, contentId = '', lastCount, index){
    this.cardHovered = true;
    try {
    const elemId = contentId + dataKey+ index;
    const cardElem = document.getElementById(elemId);
    index = index+1;
    const slickList = cardElem?.closest('.slick-list');
    lastCount = slickList?.firstElementChild?.children?.length || lastCount;
    let nextButtonDisabled = '';
    if (slickList){
      nextButtonDisabled = slickList.nextElementSibling?.getAttribute('aria-disabled');
    }
    let cardNSlide = Math.floor(this.slideToScroll);
    let translateValue = 85.4;
    let scale = {x:1.603,y:1.6};
    switch (type) {
      case 'group':
        translateValue = 22.4;
        scale = {x:1.27,y:1.277};
        cardNSlide = Math.floor(this.groupCollectionSlidesToScroll);
        break;
      case 'region':
        translateValue = 32.4;
        scale = {x:1.11,y:1.1};
        cardNSlide = Math.floor(this.topInRegionSlidesToScroll);
        break;
      default:
        translateValue = 85.4;
        scale = {x:1.61,y:1.6};
        cardNSlide = Math.floor(this.slideToScroll);
        break;
    }
    const isLastCard = ((lastCount >= cardNSlide && index === lastCount) || (nextButtonDisabled === 'false' && (index%cardNSlide) === 0))? true : false;
    if (isLastCard && cardElem) {cardElem.style.transform = `translate(-${translateValue}px, 0px) scale(${scale.x}, ${scale.y})`;return}
    const lastRemainCards = ((lastCount - (index-1)) === cardNSlide);
    const isFirstCard = ((lastRemainCards && nextButtonDisabled === 'true') || (index === 1) ||
    (((index%cardNSlide) === 1) && (lastCount - (index-1)) >= cardNSlide && nextButtonDisabled !== 'true')) ? true : false;
    if(isFirstCard && cardElem) {cardElem.style.transform = `translate(${translateValue}px, 0px) scale(${scale.x}, ${scale.y})`;return}
    } catch (error) {
      console.log(error)
    }
  }

  hoverOut(dataKey, contentId = '', index?){
    this.cardHovered = false;
    const elemId = contentId+ dataKey+ index;
    const cardElem = document.getElementById(elemId);
    if(cardElem?.style.transform) cardElem.style.transform = '';
  }

  ngOnDestroy(){
  }

}
