import { Injectable, OnInit } from '@angular/core';
import { BaseService, SettingsService, StorageService } from '../services';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { refreshToken, login, Register, logout, userSettings } from 'src/app/shared/constants';
import { UikitService } from '../services/uikit.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DatalayerService } from '../services/datalayer.service';
import { Location } from '@angular/common';
import { ConfigurationService } from '../services/configuration.service';
import { SubscriptionService } from '../services/subscription.service';
import { BULSATCODE, COUPON, VODAFONE_ROMANIA, VOUCHER } from 'src/app/shared/constants/key.constants';
import { VOUCHER_SIGNIN } from 'src/app/shared/constants/local.constants';
import { R_VOUCHER_SIGNIN } from 'src/app/shared/constants/route.constants';
import { environment } from 'src/environments/environment';
import { LoadService } from '../services/load.service';
import { CookieService } from 'ngx-cookie';
import * as $ from 'jquery'
import { PaymentService } from '../services/payment.service';
import { OperatorService } from '../services/operator.service';
import { VoucherService } from '../services/voucher.service';
import * as UAParser from 'ua-parser-js';
declare global {  interface Window {dengage: any; } }

@Injectable({
  providedIn: 'root'
})
export class LoggerService  {
  memorySource = new BehaviorSubject<any>({});
  memoryStorage = this.memorySource.asObservable();
  reset = false;
  isSVOD = 0;
  user_settings;
  nRegion;
  oldRegion;
  nCountryCode;
  voucherAccessToken = '';
  public activeVoucherErrorMsg = new BehaviorSubject('');
  currentOperator: any;
  operator:any
  BulsatData: any;
  oldAccessToken;
  newAccessToken;
  newUserId;
  loginPostData;
  loginResponse;
  browserInfo: any;
  constructor(private http: HttpClient, private baseService: BaseService, private route: ActivatedRoute,
              private router: Router, private storageService: StorageService, private uikitService: UikitService,
              private datalayerService: DatalayerService, private location: Location,
              private configService: ConfigurationService, private subscriptionService: SubscriptionService,
              private settingsService: SettingsService, private loadService:LoadService,
              private cookieService: CookieService, private paymentService: PaymentService,
              private voucherService:VoucherService) {
    const sessionStorage = this.storageService.getLocalStore('sessionStorage');
    this.memorySource.next(sessionStorage);
    window.dataLayer = window.dataLayer || [];
    this.oldAccessToken = this.storageService.getLocalStore('a_t');
    let value = new UAParser();
    let parser = value.getResult();
    let device = "Browser (Desktop)"
    if(window.innerWidth <= 768) device = "Browser (Mobile)"
    this.browserInfo =  {"manufacturer": device, "model": parser.browser.name,"version": parser.browser.version,
    "osName": parser.os.name, "osVersion": parser.os.version ,"displayWidth":  window.innerWidth, "displayHeight": window.innerHeight}
  }

  updatedMemoryStorage(data) {
    this.memorySource.next(data);
  }

  loginCheck(data):Observable<any>{
    data['user_device_info'] = this.browserInfo;
    const loginAPI: string = environment.apiUrl + environment.version + login;
    return this.http.post(loginAPI, data).pipe(
      map((response:any)=>{
        if(response.status_code === 200){
          this.baseService.signLoaderSource.next(false)
          return response;
      }
      }), catchError((error)=>{
        this.baseService.signinCounterErrorFn();
        if(data.login_by == "manual"){
          data.login_by = "Email";
        }
        const datalayerData = {
          event: 'GAEvent',
          eventCategory: 'Membership Actions',
          eventAction: 'Login',
          eventLabel: 'Error -' + data.login_by + '/Username',
          loginStatus: 'False',
          userId: '',
          notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
          region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
          platform: this.baseService.browserType,
          userType: this.storageService.getLocalStore('user_type'),
          operatorName: this.storageService.getLocalStore('operator_name')
        };
        this.datalayerService.addDatalayer(datalayerData);
        this.baseService.signLoaderSource.next(false);
        this.uikitService.staticErrorMessage(error.error.error.message);
        return error
      })
    )
  }

  async loginFn(data) {
    let response = this.loginResponse;
    this.clearVariable();
        if (response.status_code === 200) {
          this.baseService.signLoaderSource.next(false)
          const respData = response.data;
          const discountType = this.storageService.getLocalStore('discount_type');
          const discountCode = this.storageService.getLocalStore('discount_code');
          const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
          let couponResp;
          if (respData?.user_type !== 0 && ((operatorVoucher && discountCode && discountType === VOUCHER) || (discountCode && discountType === VOUCHER) || (discountCode && (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA)))) {
            this.voucherAccessToken = respData.access_token;
            if (discountType === VOUCHER) {
              const Coupendata = { voucher_code: discountCode, d_type: 'web' };
              couponResp = await this.subscriptionService.applyCoupon(Coupendata);
            } else if (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA) {
              const coupenData: any = {
                code: discountCode,
                d_type: 'web',
                operator_reference_number: this.storageService.getLocalStore('operatorReference'),
                customer_identifier_type: this.storageService.getLocalStore('customerIdentifierType'),
              };
              coupenData['operator'] = this.baseService.telcoSlug;
              couponResp = await this.voucherService.bulsatSubcription(coupenData);
            }
            if (couponResp?.error && couponResp.error?.code !== 1002) {
              this.voucherAccessToken = '';
              this.activeVoucherErrorMsg.next(couponResp.error.error?.message);
              return;
            }
          }
          if(respData.user_login_by == "manual"){
            respData.user_login_by = "Email";
          }
          const uId = respData.user_id;
          if(this.settingsService.dEngageFlag && window.dengage) window.dengage('setContactKey', uId);
          const dId: string = this.storageService.getLocalStore('d_id');
          const bodyScript = "<script>var _paq = window._paq = window._paq || []; window._paq.push(['appendToTrackingUrl', 'new_visit=1']); _paq.push(['setUserId', '" + uId + "']);_paq.push(['setCustomVariable',1,'deviceId', '" + dId + "','visit']); window._paq.push(['trackPageView']); window._paq.push(['appendToTrackingUrl', '']);</script>";
          $('body').append(bodyScript);
          let userType = 'Free User';
          let operator_name = '';
          this.storageService.setLocalStore('a_t', respData.access_token);
          this.storageService.setLocalStore('r_t', respData.refresh_token);
          this.storageService.setLocalStore('u_id', uId);
          this.storageService.setLocalStore('logged_in', respData.logged_in);
          if(respData.user_email_notified == 1){
            localStorage.setItem('emailNotify','True');
          }
          if(respData.user_email_notified != 1){
            localStorage.setItem('emailNotify','False');
          }
          let cookieuName = 'u_id';
          let cookieuValue = uId;
          document.cookie = cookieuName + "=" + cookieuValue + ";domain="+environment.cookieDomain+";path=/";
          const updateMemoryData = {
            login_by: respData.user_login_by,
            user_picture: respData.user_picture,
            user_name: respData.user_name,
            user_type: respData.user_type,
            user_telco: respData.user_telco,
            is_voucher_applied: respData?.is_voucher_applied,
            partner_name: respData?.voucher?.partner_name,
            partner_description: respData?.voucher?.partner_description,
            one_time_subscription: respData.one_time_subscription,
            u_id: uId
          };
          this.paymentService.paymentMethodLength = respData.stripe_payment_method.length;
          if (respData.user_telco) {
            userType = 'Operator User';
            operator_name = respData.user_telco;
          } else if (respData.is_voucher_applied && respData.is_voucher_applied == 1) {
            userType = 'Voucher User';
            operator_name = respData.voucher.partner_name + '-' + respData.voucher.partner_description;
          } else if (respData.user_type == 1) {
            userType = 'Paid User';
          }
          this.baseService.showCardData.next({ no_past_due: true, session_id: undefined })
          this.storageService.setLocalStore('user_type', userType);
          this.storageService.setLocalStore('operator_name', operator_name);
          this.memorySource.next(updateMemoryData);
          this.storageService.setLocalStore('sessionStorage', updateMemoryData);
          this.storageService.setLocalStore('event_user_status', respData.user_type);
          this.baseService.guestSource.next(false);
          const datalayerData = {
            event: 'GAEvent',
            eventCategory: 'Membership Actions',
            eventAction: 'Login',
            eventLabel: 'Success - ' + respData.user_login_by,
            loginStatus: 'True',
            userId: uId,
            notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
            region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
            platform: this.baseService.browserType,
            userType: this.storageService.getLocalStore('user_type'),
            operatorName: this.storageService.getLocalStore('operator_name')
          };
          this.datalayerService.addDatalayer(datalayerData);
          // if (respData.user_telco !== '') {
          //   let loginSucessMsg = this.configService.localeKeys?.login_msg_login_succ_def;
          //   if (this.router.url !== R_VOUCHER_SIGNIN) {
          //     this.uikitService.staticSuccessMessage(loginSucessMsg);
          //   }
          // }
          const authenticate = this.storageService.getLocalStore('authenticate');
          const contentURL = this.storageService.getLocalStore('contentURL');
          const queryParamTv = this.route.snapshot.queryParams['app'];
          await this.getUserData();
          if (queryParamTv === this.baseService.keyQueryParamTV || queryParamTv === this.baseService.keyQueryParamAppleTV){
            if(this.settingsService.activateYourTvFromTVCode){
              this.router.navigateByUrl('account/activate-tv');
            };
            if(this.settingsService.activateYourTvFromWebCode){
              this.router.navigateByUrl('account/authenticate');
            };
          } else if ((respData.is_voucher_applied === "1" || respData.user_type === 1) && this.router.url === R_VOUCHER_SIGNIN) {
            if (respData.is_voucher_applied === "1") {
              this.uikitService.staticErrorMessage(this.configService.localeKeys?.already_active_voucher || 'You have an active voucher already');
            } else if (respData.user_type === 1) {
              this.uikitService.staticErrorMessage(this.configService.localeKeys?.pay_fail_title_duplicate || 'You already have an active subscription!');
            }
            this.router.navigateByUrl('/home')
          } else if (discountCode && (discountType === VOUCHER || discountType === BULSATCODE || discountType === VODAFONE_ROMANIA)) {
            let couponResp: any;
            if (discountType === VOUCHER) {
              const Coupendata = { voucher_code: discountCode, d_type: 'web' };
              couponResp = await this.subscriptionService.applyCoupon(Coupendata);
            } else if (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA) {
              const Coupendata = {
                code: discountCode, d_type: 'web',
                operator_reference_number: this.storageService.getLocalStore('operatorReference'),
                customer_identifier_type: this.storageService.getLocalStore('customerIdentifierType'),
              };
              couponResp = await this.voucherService.bulsatSubcription(Coupendata);
            }
            if (couponResp.status_code === 200) {
              this.storageService.setLocalStore(VOUCHER_SIGNIN, '1');
              const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
              if (operatorVoucher) {
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: { step: '5', auth: null },
                  queryParamsHandling: 'merge'
                })
              } else {
                this.router.navigateByUrl('/voucher/success');
              };
            } else if (couponResp.error && couponResp.error.code !== 1002) {
                this.voucherAccessToken = '';
                this.activeVoucherErrorMsg.next(couponResp.error.error?.message);
                this.removeLogoutData();
                return;
            }
            this.storageService.removeLocalStore('discount_code');
            this.storageService.removeLocalStore('discount_type');
            this.storageService.removeLocalStore('operator_voucher');
          } else if (discountCode && discountType === COUPON) {
            this.storageService.setLocalStore(VOUCHER_SIGNIN, '1');
            this.router.navigateByUrl('/voucher/plan');
          } else if (authenticate) {
            this.storageService.removeLocalStore('authenticate');
            if(this.settingsService.activateYourTvFromTVCode){
                this.router.navigateByUrl('account/activate-tv');
            };
            if(this.settingsService.activateYourTvFromWebCode){
                this.router.navigateByUrl('account/authenticate');
            };
          } else if (contentURL) {
            this.storageService.removeLocalStore('contentURL');
            location.href = contentURL;
          } else {
                this.router.navigateByUrl('/home')
          }
          this.storageService.removeLocalStore('authErrorCounter');
          this.baseService.signinCaptchaValidation.next(0);
        } else {
          this.baseService.signinCounterErrorFn();
          if(data.login_by == "manual"){
            data.login_by = "Email";
          }
          const datalayerData = {
            event: 'GAEvent',
            eventCategory: 'Membership Actions',
            eventAction: 'Login',
            eventLabel: 'Error -' + data.login_by + '/Username',
            loginStatus: 'False',
            userId: '',
            notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
            region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
            platform: this.baseService.browserType,
            userType: this.storageService.getLocalStore('user_type'),
            operatorName: this.storageService.getLocalStore('operator_name')
          };
          this.datalayerService.addDatalayer(datalayerData);
        };
  }
  getUserSettings:any = async() => {
    let settingsUrl = environment.apiUrl + environment.v3_version + userSettings;
    let  userId  = this.storageService.getLocalStore('u_id');
    let  deviceId = this.storageService.getLocalStore('d_id');
    let localeSetting = this.storageService.getLocalStore('locale')
    let logged_in:any = 1;
    let params = new HttpParams()
    .set('d_type', 'web')
    .set('logged_in', logged_in)
    .set('locale', localeSetting);
    let paramurl =  '/' + deviceId + '/' + userId;
    settingsUrl = settingsUrl + paramurl;
    return new Promise((resolve, reject) => {
      return this.http.post<any>(settingsUrl, {}, { params: params })
        .subscribe(
          async (response) => {
            if (response.status_code === 200) {
              const respData = response.data;
              return resolve(respData);
            }
            else {
              this.uikitService.notifyError(response.error);
              return resolve(response.error);
            }
          },
          (error) => {
            return resolve(error);
          }
        );
    });
  }
  async getUserData(){
    this.user_settings = await this.getUserSettings();
    this.nCountryCode = this.user_settings.configuration['country_code'];
    this.nRegion = this.user_settings.configuration.region['region_code'];
    this.oldRegion = this.storageService.getLocalStore('region');
    if(this.nRegion != this.oldRegion) await this.setNewRegion();
  }
  setNewRegion(){
    return new Promise<boolean>((resolve, reject) => {
      this.storageService.setLocalStore('country_code',this.nCountryCode);
      this.storageService.setLocalStore('region',this.nRegion);
      const pathNameArr = location.pathname.split('/');
      const regionPath = pathNameArr[1];
      location.pathname = location.pathname.replace(regionPath, this.nRegion);
      resolve(true);
    })
  }

  signUpFn(data) {
  let email_notify = 'False';
   if(data.email_notification == 1) {
     email_notify = 'True';
     localStorage.setItem('emailNotify','True');
  }
  if(data.email_notification != 1) localStorage.setItem('emailNotify','False');
  const queryparams  = this.route.snapshot.queryParams
  if(this.baseService.telcoSlug && queryparams?.auth === 'signup') data['operator'] = this.baseService.telcoSlug;
  this.isSVOD = this.settingsService.isSvodEnabled;
  const signUpAPI = environment.apiUrl + environment.version + Register;
  const region = this.storageService.getLocalStore('region');
  data['unique_device_id'] = this.storageService.getLocalStore('uniq_id');
  data['user_device_info'] = this.browserInfo;
  console.log(data)
    return new Promise((resolve, reject) => {
      this.http.post<any>(signUpAPI, data).subscribe(
        async (response: any) => {
          if (response.status_code === 200) {
            this.baseService.signLoaderSource.next(false);
            if(this.settingsService.dEngageFlag && window.dengage){
              window.dengage('setContactKey', response.data.user_id);
            }
            const datalayerData = {
              event: 'GAEvent',
              eventCategory: 'Membership Actions',
              eventAction: 'Register',
              eventLabel: 'Success',
              loginStatus: 'True',
              userId: response.data.user_id,
              notificationStatus: email_notify,
              region: (region ? region : ''),
              platform: this.baseService.browserType,
              userType: this.storageService.getLocalStore('user_type'),
              operator: this.storageService.getLocalStore('operator_name')
            };
            this.datalayerService.addDatalayer(datalayerData);
            if (response.data.verifyEmail === 1) {
              this.storageService.setLocalStore('mail', data.email);
              this.uikitService.notifySuccess(response);
              this.router.navigateByUrl('/auth/verify');
              resolve(response);
            } else {
              const respData = response.data;
              const uId = respData.user_id;
              if(this.settingsService.dEngageFlag && window.dengage) window.dengage('setContactKey', uId);
              const dId: string = this.storageService.getLocalStore('d_id');
              const bodyScript = "<script>var _paq = window._paq = window._paq || []; window._paq.push(['appendToTrackingUrl', 'new_visit=1']); _paq.push(['setUserId', '" + uId + "']);_paq.push(['setCustomVariable',1,'deviceId', '" + dId + "','visit']); window._paq.push(['trackPageView']); window._paq.push(['appendToTrackingUrl', '']);</script>";
              $('body').append(bodyScript);
              this.storageService.setLocalStore('a_t', respData.access_token);
              this.storageService.setLocalStore('r_t', respData.refresh_token);
              this.storageService.setLocalStore('u_id', uId);
              this.storageService.setLocalStore('logged_in', respData.logged_in);
              this.storageService.setLocalStore('operator_reference_number',respData.operator_reference_number);
              this.storageService.setLocalStore('customer_identifier_type',respData.customer_identifier_type);
              this.paymentService.paymentMethodLength = respData.stripe_payment_method.length;
              let cookieuName = 'u_id';
              let cookieuValue = uId;
              document.cookie = cookieuName + "=" + cookieuValue + ";domain="+environment.cookieDomain+";path=/";
              const updateMemoryData = {
                login_by: respData.user_login_by,
                user_picture: respData.user_picture,
                user_name: respData.user_name,
                user_type: respData.user_type,
                user_telco: respData.user_telco,
                one_time_subscription: respData.one_time_subscription,
                u_id: respData.user_id
              };
              this.memorySource.next(updateMemoryData);
              this.storageService.setLocalStore('sessionStorage', updateMemoryData);
              this.storageService.setLocalStore('event_user_status', respData.user_type);
              this.baseService.guestSource.next(false);
              const queryParamTv = this.route.snapshot.queryParams['app'];
              const discountType = this.storageService.getLocalStore('discount_type');
              const discountCode = this.storageService.getLocalStore('discount_code');
              if (discountCode && discountType === VOUCHER && this.isSVOD) {
                const Coupendata = { voucher_code: discountCode, d_type: 'web' };
                const couponResp: any = await this.subscriptionService.applyCoupon(Coupendata);
                if (couponResp.status_code === 200) {
                  const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
                  if(operatorVoucher) {
                    this.router.navigate([], {
                      relativeTo: this.route,
                      queryParams: { step: '5', auth: null },
                      queryParamsHandling: 'merge'
                    })
                  } else this.router.navigateByUrl('/voucher/success');
                } else {
                  if (couponResp.error && couponResp.error.code !== 1002) {
                    this.uikitService.notifyError(couponResp);
                  }
                  this.router.navigateByUrl('/home')
                }
                this.storageService.removeLocalStore('discount_code');
                this.storageService.removeLocalStore('discount_type');
                this.storageService.removeLocalStore('operator_voucher');
              } else if(discountCode && this.isSVOD && (discountType === BULSATCODE || discountType === VODAFONE_ROMANIA)){
                const Coupendata = { code: discountCode, d_type: 'web',operator_reference_number:this.storageService.getLocalStore('operatorReference'),customer_identifier_type:this.storageService.getLocalStore('customerIdentifierType')};
                  const couponResp2: any = await this.voucherService.bulsatSubcription(Coupendata);
                  if (couponResp2.status_code === 200) {
                    const operatorVoucher = this.storageService.getLocalStore('operator_voucher');
                    if(operatorVoucher) {
                      this.router.navigate([], {
                        relativeTo: this.route,
                        queryParams: { step: '5', auth: null },
                        queryParamsHandling: 'merge'
                      })
                    } else this.router.navigateByUrl('/voucher/success');
                  } else {
                    if (couponResp2.error && couponResp2.error.code !== 1002) {
                      this.uikitService.notifyError(couponResp2);
                    }
                  }
               } else if (discountCode && discountType === COUPON && this.isSVOD) {
                if(response.data.display_payment === 1) this.router.navigateByUrl('/voucher/plan');
                else this.router.navigateByUrl('/home');
              } else if (response.data.display_payment === 1 && this.isSVOD) {
                if (queryParamTv === this.baseService.keyQueryParamTV){
                  this.baseService.routeFn('/auth/plan', this.baseService.keyQueryParamTV);
                } else {
                  this.baseService.routeFn('/auth/plan', this.baseService.keyQueryParamAppleTV);
                }
              } else {
                this.router.navigateByUrl('/home');
              }
              this.baseService.signinCaptchaValidation.next(0);
              this.storageService.removeLocalStore('authErrorCounter');
              resolve(response);
            }
          }
          else {
            // this.baseService.counterErrorFn();
            const datalayerData = {
              event: 'GAEvent',
              eventCategory: 'Membership Actions',
              eventAction: 'Register',
              eventLabel: 'Error',
              loginStatus: 'False',
              userId: '',
              notificationStatus: email_notify,
              region: (region ? region : ''),
              platform: this.baseService.browserType,
              userType: this.storageService.getLocalStore('user_type'),
              operator: this.storageService.getLocalStore('operator_name')
            };
            this.datalayerService.addDatalayer(datalayerData);
            this.baseService.signLoaderSource.next(false)
            resolve(response);
          }
        },
        (error: HttpErrorResponse) => {
          // this.baseService.counterErrorFn();
          if (error?.error?.error?.code !== 1023) {
            this.uikitService.staticErrorMessage(error?.error?.error?.message);
          }
          const datalayerData = {
            event: 'GAEvent',
            eventCategory: 'Membership Actions',
            eventAction: 'Register',
            eventLabel: 'Error' ,
            loginStatus: 'False',
            userId: '',
            notificationStatus: email_notify,
            region: (region ? region : ''),
            platform: this.baseService.browserType,
            userType: this.storageService.getLocalStore('user_type'),
            operator: this.storageService.getLocalStore('operator_name')
          };
          this.datalayerService.addDatalayer(datalayerData);
          this.baseService.signLoaderSource.next(false)
          resolve(error)
        }
      );
    })

  }

  refreshToken() {
    const refreshAPI = environment.apiUrl + environment.version + refreshToken;
    const dId: string = this.storageService.getLocalStore('d_id');
    const gId: string = this.storageService.getLocalStore('g_id');
    const uId: string = this.storageService.getLocalStore('u_id');
    let params: HttpParams = new HttpParams()
      .set('d_type', 'web')
      .set('d_id', dId);
    if (uId) {
      params = params.append('u_id', uId);
    }
    else {
      params = params.append('g_id', gId);
    }
    this.http.get(refreshAPI, { params }).subscribe((response: any) => {
      if (response.status_code === 200) {
        const respData = response.data;
        this.storageService.setLocalStore('a_t', respData.access_token);
        this.storageService.setLocalStore('r_t', respData.refresh_token);
      }
    }, (error: HttpErrorResponse) => {
      console.log(error);
    });
  }

  logout(reset = false) {
    this.reset = reset;
    const dId: string = this.storageService.getLocalStore('d_id');
    const logoutAPI: string = environment.apiUrl + environment.version + logout + dId;
    const params: HttpParams = new HttpParams()
      .set('d_type', 'web');
    this.http.get(logoutAPI, { params }).subscribe((response: any) => {
      this.baseService.loaderSource.next(false);
      if (response.status_code === 200) {
        const uId: string = this.storageService.getLocalStore('u_id');
        const region: string = this.storageService.getLocalStore('region');
        if(this.settingsService.dEngageFlag && window.dengage){
          window.dengage('setContactKey', null);
        }
        const datalayerData = {
          event: 'GAEvent',
          eventCategory: 'Membership Actions',
          eventAction: 'Logout',
          eventLabel: 'Success',
          loginStatus: 'False',
          userId: (uId ? uId : ''),
          notificationStatus:   localStorage.getItem('emailNotify') ||  'False',
          region: (region ? region : ''),
          platform: this.baseService.browserType,
          userType: this.storageService.getLocalStore('user_type'),
          operator: this.storageService.getLocalStore('operator_name')
        };
        this.datalayerService.addDatalayer(datalayerData);
        this.removeLogoutData();
        this.uikitService.notifySuccess(response);
        if(this.settingsService.dEngageFlag && window.dengage) window.dengage('setContactKey', '');
        // if (this.baseService.loginMode === 1) {
        //   //this.router.navigateByUrl('/index/signin');
        // }
        // else {
        //   //this.router.navigateByUrl('/home');
        // }
        if(!reset){
          let utm_url = this.settingsService.getUTMurl(environment.paywallURL);
          setTimeout(()=>{
            window.location.href = utm_url;
          }, 1500);
        }
        this.baseService.showCardData.next({ no_past_due: true, session_id: undefined });
        // setTimeout(()=>{
        //   window.location.href = utm_url;
        // }, 2000);
      } else {
        this.uikitService.notifyError(response);
      }
    }, (error: HttpErrorResponse) => {
      if (error && error.error.error.code !== 1002) {
        this.uikitService.notifyError(error);
      }
      this.baseService.loaderSource.next(false);
    });
  }

  errorLogout() {
    if(this.reset == false){
      this.removeLogoutData()
      const routeUrl = this.settingsService.routeUrl || environment.routeUrl;
      setTimeout(()=>{
        window.location.assign(routeUrl);
      }, 3000);
    }
  }

  async removeLogoutData() {
    if(this.settingsService.dEngageFlag && window.dengage){
      window.dengage('setContactKey', '');
    }
    this.memorySource.next(undefined);
    this.storageService.removeLocalStore('a_t');
    this.storageService.removeLocalStore('r_t');
    this.storageService.setLocalStore('logged_in', '0');
    this.storageService.removeLocalStore('u_id');
    document.cookie = 'u_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.storageService.removeLocalStore('sessionStorage');
    this.storageService.removeLocalStore('contentURL');
    this.storageService.removeLocalStore('event_user_status');
    this.storageService.removeLocalStore('operator_name');
    this.storageService.removeLocalStore('user_type');
    this.storageService.removeLocalStore('epg_live_channel');
    this.storageService.removeLocalStore('sso_or_ftp');
    this.storageService.removeLocalStore('operator_voucher');
    this.storageService.removeLocalStore('discount_type');
    this.storageService.removeLocalStore('discount_code');
    this.baseService.guestSource.next(true);
    await this.loadService.getToken();
  }


  resetPassword(data){
    const resetPasswordAPI = environment.apiUrl + 'v2/auth/password/reset/update';
    this.http.post<any>(resetPasswordAPI, data).subscribe((response)=>{
      this.uikitService.staticSuccessMessage("Password successfully changed! Please Login Again.");
      this.router.navigateByUrl('/auth/signin');
    },(error: HttpErrorResponse) => {
      console.log(error.error.error.code)
      if(error.error.error.code === 1116){
        this.uikitService.staticErrorMessage("The link has already expired. Please try once again resetting password from the portal/app.");
        this.router.navigateByUrl('/auth/forgot')
      }else this.uikitService.staticErrorMessage("Password reset failed");
    })
  }

  clearVariable(){
    this.oldAccessToken = undefined;
    this.newAccessToken = undefined;
    this.newUserId = undefined;
    this.loginPostData = undefined;
    this.loginResponse = undefined
  }
}
