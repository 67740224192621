import { Component, Input, OnInit } from '@angular/core';
import { BaseService, SettingsService, StorageService } from 'src/app/core/services';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './' + environment.htmlAppName + '/navigation.component.html',
  styleUrls: ['./' + environment.appName + '/navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  startState: any;
  @Input() routes;
  logohref:any;
  @Input() value;
  @Input() guest;
  logo;

  constructor(private baseService: BaseService, public settingsService: SettingsService, private storageService: StorageService
              ,public configService: ConfigurationService, private cookieService: CookieService, private router: Router) {
    this.logo = this.settingsService.logo;
  }

  ngOnInit(): void {
    if (this.baseService.loginMode === 0) {
      if (!this.storageService.getLocalStore('u_id')) {
        this.baseService.guestSource.next(true);
      }
      this.startState = '/home';
    } else {
      this.startState = '/index/signup';
    }
  }

  redirectURL() {
    const user_id = this.storageService.getLocalStore('u_id');
    if(user_id) {
    //  window.location.href = this.startState;
    this.router.navigateByUrl(this.startState);
    } else {
      let utm_url = this.settingsService.getUTMurl();
      window.location.href = utm_url;
    }
  }
}
