export const environment = {
    production: true,
    environmentName: 'Staging',
    apiUrl:'https://film1preprodapi.tv2zdev.com/preprod/',
    socialUrl:'https://film1preprodapi.tv2zdev.com/preprod/',
    version:'v2/',
    v3_version:'v3/',
    routeUrl:'https://film1preprod.tv2zdev.com/',
    cookieDomain:'tv2zdev.com',
    paywallURL: 'https://film1ssr.tv2zdev.com/',
    appName: "film1",
    appVersion: '1.0.0',
    htmlAppName: 'film1'
}
