import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { assetsCategory, contents, catAssets, seriesAssest } from 'src/app/shared/constants';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  categories = new BehaviorSubject([]);
  channelsList;
  bannerData: any;
  contentData;
  constructor(private http: HttpClient, private uikitService: UikitService,
    private router: Router) { }

  getCategories(): Observable<any> {
    const homeURL = environment.apiUrl + environment.version + assetsCategory;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(homeURL, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            const respData = response.data;
            this.categories.next(respData.categories);
            if (respData.livechannels) {
              this.channelsList = respData.livechannels;
            }
            return response.data;
          }
          else {
            this.uikitService.notifyError(response);
          }
        }), catchError((error) => {
          if (error.error && error.error.code !== 1002) {
            this.uikitService.notifyError(error);
          }
          return error;
        })
    );
  }

  getCategoryContents(catTitle) {
    const catgoryURL = environment.apiUrl + environment.v3_version + catAssets + catTitle + contents;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(catgoryURL, { params })
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              const respData = response.data;
              this.contentData = response.data;
              return respData;
            }
            else {
              this.uikitService.notifyError(response);
              this.router.navigateByUrl('/404');
            }
          }
          , (error: HttpErrorResponse) => {
            this.uikitService.notifyError(error);
            this.router.navigateByUrl('/404');
          }
        )
      );
  }

  getSeriesContents(id) {
    const catgoryURL = environment.apiUrl + environment.version + seriesAssest + id;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(catgoryURL, { params })
      .pipe(
        map(
          (response: any) => {
            if (response.status_code === 200) {
              const respData = response.data;
              return respData;
            }
            else {
              this.uikitService.notifyError(response);
              this.router.navigateByUrl('404');
            }
          }), catchError((error) => {
            if (error.error && error.error.code !== 1002) {
              this.uikitService.notifyError(error);
              this.router.navigateByUrl('404');
            }
            return error;
          })
      );
  }

  getNextVersionCategories(): Observable<any> {
    const homeURL = environment.apiUrl + environment.v3_version + assetsCategory;
    const params = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(homeURL, { params }).pipe(
      map(
        (response: any) => {
          if (response.status_code === 200) {
            const respData = response.data;
            this.categories.next(respData);
            // if (respData.livechannels) {
            //   this.channelsList = respData.livechannels;
            // }
            return response.data;
          }
          else {
            this.uikitService.notifyError(response);
          }
        }), catchError((error) => {
          if (error.error && error.error.code !== 1002) {
            this.uikitService.notifyError(error);
          }
          return error;
        })
    );
  }
}
