<div *ngIf="data" class="box video-box-row ">
    <ngx-slick-carousel (init)="slickInit($event)" [id]="'slick-'+ dataIndex" #slickModal="slick-carousel" [config]="cardConfig" class="slider video-slider multiple-items collection-widget-slider" (beforeChange)="beforeChange($event)" (afterChange)="afterChange($event)" *ngIf="data.component !== 'livechannel_widget' && data.component !== 'group_collection_widget' && data.component !== 'top_in_region_widget'">
        <div ngxSlickItem *ngFor="let value of dataList;let i=index" class="slide-box tile video-box lower-z-index">
            <div [ngClass]="(data.key === 'continue') ? 'tile-media' : 'normal-tile-media' "  [id]="value.contentId+data.key+i" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (mouseover)="hoverIn(data.key, 'card', value.contentId, dataList.length, i)" (mouseout)="hoverOut(data.key, value.contentId,i)">
                <a>
                    <img (click)="redirect(value,data.key,data.title)" loading="lazy" class="tile-img" src="{{imagePath+value.banner_medium}}" alt="" />
                    <div (click)="redirect(value,data.key,data.title)" class="img-gradient"></div>
                    <div class="actions-card">
                        <span class="actions-card-container">
                            <span class = "action-card-tooltip" *ngIf="!(baseService.guest | async)">
                                <img (click)="closeSearch();playContentService.playVideo(value,'play')" class="cursor-pointer card-icon" src="assets/{{appName}}/img/card-play.svg">
                                <span class = "action-card-tooltip-text" [ngClass]="data.key === 'continue'? 'margin-left': 'margin-null'">{{configService?.localeKeys?.player_play_video || 'Play'}}</span>
                            </span>
                            <span class = "action-card-tooltip" *ngIf="!value?.wishlist && !guest && !value.is_livechannel">
                                <img (click)="playContentService.addToWishlist(value)"  class="cursor-pointer card-icon" src="assets/{{appName}}/img/wishlist_add.svg">
                                <span class = "action-card-tooltip-text" [ngClass]="data.key === 'continue'? 'margin-left': 'margin-null'">{{configService?.localeKeys?.home_pop_menu_wishlist_add || 'Add to wishlist'}}</span>
                            </span>
                            <span class = "action-card-tooltip" *ngIf="value?.wishlist && !guest && !value.is_livechannel">
                                <img (click)="playContentService.removeWishlist(value)" class="cursor-pointer card-icon" src="assets/{{appName}}/img/wishlist_remove.svg">
                                <span class = "action-card-tooltip-text" [ngClass]="data.key === 'continue'? 'margin-left': 'margin-null'">{{configService?.localeKeys?.remove_watchlist_definition || 'Remove from watchlist'}}</span>
                            </span>
                            <span class = "action-card-tooltip">
                                <img (click)="redirect(value,data.key,data.title)" class="cursor-pointer card-icon" src="assets/{{appName}}/img/content_info.svg">
                                <span class = "action-card-tooltip-text" [ngClass]="data.key === 'continue'? 'margin-left': 'margin-null'">{{configService?.localeKeys?.see_info_title || 'See info'}}</span>
                            </span>
                            <span *ngIf="!(baseService.guest | async) && data.key == 'continue'" class = "action-card-tooltip">
                                <img (click)="removeContinue(value?.continue_id)" [ngClass]="preventClick ? 'no-pointer' : ''" class="cursor-pointer card-icon" src="assets/{{appName}}/img/remove-icon.svg">
                                <span class = "action-card-tooltip-text"  [ngClass]="data.key === 'continue'? 'margin-left': 'margin-null'">{{configService?.localeKeys?.home_pop_menu_continue_remove || 'Remove from this list'}}</span>
                            </span>
                        </span>
                        <span *ngIf="value?.is_series && !(baseService.guest | async) && data.key == 'continue'" class="card-duration">
                            <span *ngIf="value?.season_position">
                                {{configService?.localeKeys?.homepg_cont_watch_series_notation}}{{value?.season_position}}&nbsp;-&nbsp;{{configService?.localeKeys?.homepg_cont_watch_episode_notation}}{{value?.position}}
                            </span>
                            <span *ngIf="value?.season_position == 0">
                            {{configService?.localeKeys?.homepg_cont_watch_episode_notation}}{{value?.position}}
                            </span>
                        </span>
                        <!-- <span *ngIf="!value?.is_series && !(baseService.guest | async) && data.key == 'continue' && value?.duration_min" class="card-duration">
                            <span>{{value.duration_min | movieDuration}}</span>
                        </span> -->
                    </div>
                    <mat-progress-bar *ngIf="data.key == 'continue'" [value]=[value.percentage]></mat-progress-bar>
                    <div class="onhover-card" [ngStyle]="{'top': settingsService.displayOnTitle ? '120px': '130px'}">
                        <div class="meta-card">
                            <p *ngIf="settingsService?.displayOnTitle" class="meta-title">{{value?.title}}</p>
                            <p class="meta-data-truncate" [ngStyle]="{'margin': settingsService.displayOnTitle ? 'inherit': '0 0 20px 0'}">
                                <span class="text-style" *ngIf="value?.publish_time">{{value?.publish_time}}</span>
                                <span class="text-style" *ngIf="value?.publish_time && value?.age && value?.genre">|</span>
                                <span class="text-style" *ngIf="value?.genre">{{configService?.localeKeys[value?.genre?.split(",")[0]]}}</span>
                                <span class="text-style" *ngIf="(value?.is_series == 1 && value?.total_seasons) || (value?.is_series == 0 && value.duration_min)">|</span>
                                <span *ngIf="value?.is_series == 1">
                                    <span class="text-style" *ngIf="value?.total_seasons">{{value?.total_seasons}} {{configService.localeKeys?.kws_kw_sesons_def}}</span>
                                </span>
                                <span *ngIf="value?.is_series == 0">
                                    <span class="text-style">{{value.duration_min | movieDuration}}</span>
                                </span>                              
                            </p>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div ngxSlickItem *ngIf="!noViewMore && (data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)" class="slide-box tile video-box lower-z-index" >
            <div [id]="'view-all'+data.key+dataList.length" class="view-all-tile transition-class" (click)="viewMore(data)" (mouseover)="hoverIn(data.key, 'card', 'view-all', dataList.length+1, dataList.length)" (mouseout)="hoverOut(data.key, 'view-all', dataList.length)">
                <img loading="lazy" src="assets/{{appName}}/img/view-all-plus.svg" alt="" />
                <a class="txt-trunc"> {{configService?.localeKeys?.homepg_viewmore_card_title_part1 || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.homepg_viewmore_card_title_part2 || 'more'}} </a>
            </div>
        </div>
    </ngx-slick-carousel>
    <ngx-slick-carousel (init)="slickInit($event)" [id]="'slick-' + dataIndex" *ngIf="key === 'livechannel_widget' && settingsService.enableLive" #slickModal="slick-carousel" [config]="liveConfig" class="slider video-slider multiple-items live-channel-widget-slider" id="channelSlider">
        <div ngxSlickItem *ngFor="let value of dataList">
            <div class="live-channel">
                <div *ngIf="appName === 'film1'" class="live-img d-flex-center">
                    <img class="poster" [src]="imagePath+value.banner_image">
                    <img loading="lazy" class="channel-icon" src="{{imagePath+value?.icon}}">
                    <div class="live-channel-img-gradient" (click)="playContentService.playVideo(value, 'play');"></div>
                    <div class="img-border-style" (click)="playContentService.playVideo(value, 'play');">
                        <app-dynamic-svg-color [color]="value?.img_border_color ? value?.img_border_color : '#FFAE00'"></app-dynamic-svg-color>
                    </div>
                    <img (click)="playContentService.playVideo(value, 'play');" class="img-border-style" loading="lazy" src="assets/{{appName}}/img/live-channels-border-arrows.svg">
                    <div (click)="playContentService.playVideo(value, 'play');" class="on-air">
                        <img src="assets/{{appName}}/img/on-air.svg">
                        <span>{{configService.localeKeys?.on_air}}</span>
                    </div>
                </div>
                <div *ngIf="appName === 'filmbox'" >
                    <div class="live-img d-flex-center">
                        <img class="poster" [src]="imagePath+value.banner_image">
                    </div>
                    <div class="on-air" (click)="playContentService.playVideo(value, 'play');">
                        <img src="assets/{{appName}}/img/on-air.svg">
                        <span>{{configService.localeKeys?.on_air}}</span>
                    </div>
                    <div class="lines-box-container" (click)="playContentService.playVideo(value, 'play');">
                        <div class="left-lines">
                            <app-dynamic-svg-color [type]="'leftSvg'" [color]="value?.img_border_color ? value?.img_border_color : '#ec1e24'"></app-dynamic-svg-color>
                        </div>
                        <div class="right-lines">
                            <app-dynamic-svg-color [type]="'rightSvg'" [color]="value?.img_border_color ? value?.img_border_color : '#ec1e24'"></app-dynamic-svg-color>
                        </div>
                    </div>
                    <img loading="lazy" class="channel-icon" src="{{imagePath+value?.icon}}" (click)="playContentService.playVideo(value, 'play');">
                </div>
                <div class="d-flex j-center live-hover-card">
                    <span class="live-channel-actions-card-container">
                        <span (click)="closeSearch();playContentService.playVideo(value, 'play');" class = "live-channel-action-card-tooltip" *ngIf="!(baseService.guest | async)">
                            <img class="cursor-pointer card-icon " src="assets/{{appName}}/img/card-play.svg ">
                            <span class = "live-channel-action-card-tooltip-text">{{configService?.localeKeys?.player_play_video || 'Play'}}</span>
                        </span>
                        <span (click)="closeSearch();playContentService.playVideo(value, 'play');" class = "live-channel-action-card-tooltip">
                            <img class="cursor-pointer card-icon " src="assets/{{appName}}/img/content_info.svg ">
                            <span class = "live-channel-action-card-tooltip-text">{{configService?.localeKeys?.see_info_title || 'See info'}}</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div ngxSlickItem class="slide-box tile video-box lower-z-index" *ngIf="(data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)">
            <div class="live-channel-view-all transition-class"  (click)="viewMore(data)">
                <img loading="lazy" class="poster" src="assets/{{appName}}/img/view-all-plus.svg">
                <a class="txt-trunc"> {{configService?.localeKeys?.homepg_viewmore_card_title_part1 || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.homepg_viewmore_card_title_part2 || 'more'}} </a>
            </div>
        </div>
    </ngx-slick-carousel>

    <ngx-slick-carousel  (init)="slickInit($event)" [id]="'slick-' + dataIndex"  *ngIf="key === 'group_collection_widget'" #slickModal="slick-carousel" [config]="groupCollectionConfig" class="slider video-slider multiple-items group-collection-widget-slider">
        <div ngxSlickItem *ngFor="let value of dataList;let i = index;" class="slide-box">
            <div [id]="(value.contentId || '')+data.key+i" class="group-collection-tile-media" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (mouseover)="hoverIn(data.key, 'group', value.contentId, dataList.length, i)" (mouseout)="hoverOut(data.key, value.contentId,i)">
                <div (click)="redirectToCollection(value, 'collection', data)" class="img-gradient"></div>
                <img  class="group-collection-tile-image" loading="lazy" src="{{imagePath+value.banner_medium}}" alt="" />
                <div (click)="redirectToCollection(value, 'collection', data)" class="image-name">
                    <span [ngClass]="(value.title).split(' ').length > 1 ? 'multi-grp-text': 'unique-grp-text'" class="genre-title-trunc">{{value?.title}}</span>
                    <div class="genre-link">{{(data.key === 'genre' ? configService?.localeKeys?.see_genre_title : configService?.localeKeys?.see_curation_definition) + ' >'}}</div>
                </div>
            </div>
        </div>
        <div ngxSlickItem class="slide-box tile video-box lower-z-index" *ngIf="(data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)">
            <div [id]="'view-all'+data.key+dataList.length" class="group-collection-view-all-tile transition-class" (click)="viewMore(data)" (mouseover)="hoverIn(data.key, 'group', 'view-all', dataList.length+1, dataList.length)" (mouseout)="hoverOut(data.key, 'view-all', dataList.length)">
                <img loading="lazy" src="assets/{{appName}}/img/view-all-plus.svg" alt="" />
                <a class="txt-trunc"> {{configService?.localeKeys?.homepg_viewmore_card_title_part1 || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.homepg_viewmore_card_title_part2 || 'more'}} </a>
            </div>
        </div>
    </ngx-slick-carousel>

    <ngx-slick-carousel (init)="slickInit($event)" [id]="'slick-'+ dataIndex" *ngIf="key === 'top_in_region_widget'" #slickModal="slick-carousel" [config]="topInRegionConfig" class="slider video-slider multiple-items top-in-region-widget-slider">
        <div ngxSlickItem *ngFor="let value of dataList;let i=index" class="slide-box">
            <div [id]="value.contentId+data.key+i" class="most-watched-tile-media" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()" (mouseover)="hoverIn(data.key, 'region', value.contentId, dataList.length, i)" (mouseout)="hoverOut(data.key, value.contentId,i)">
                <img class="most-watched-tile-image" loading="lazy" src="{{imagePath+value.banner_medium}}" alt="" />
                <div (click)="redirect(value,data.key,data.title)" class="top-in-region-img-gradient"></div>
                <div (click)="redirect(value,data.key,data.title)" class="number-style">
                    <img class="number-border" loading="lazy" src="assets/{{appName}}/img/top-in-region-num-border.svg" alt="">
                    <p class="number-name">{{i+1}}</p>
                </div>
                <div class="most-watched-onhover-card">
                    <div class="movie-info">
                        <p class="meta-data-truncate">
                            <span class="text-style" *ngIf="value?.publish_time">{{value?.publish_time}}</span>
                            <span class="text-style" *ngIf="value?.publish_time && value?.genre">|</span>
                            <span class="text-style" *ngIf="value?.genre">{{configService?.localeKeys[value?.genre?.split(",")[0]]}}</span>
                            <span class="text-style" *ngIf="(value?.is_series == 1 && value?.total_seasons) || (value?.is_series == 0 && value.duration_min)">|</span>
                            <span *ngIf="value?.is_series == 1">
                                <span class="text-style" *ngIf="value?.total_seasons">{{value?.total_seasons}} {{configService.localeKeys?.kws_kw_sesons_def}}</span>
                            </span>
                            <span *ngIf="value?.is_series == 0">
                                <span class="text-style">{{value.duration_min | movieDuration}}</span>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="most-watched-actions-card">
                    <span class="most-watched-actions-card-container">
                        <span class = "most-watched-action-card-tooltip" *ngIf="!(baseService.guest | async)">
                            <img (click)="closeSearch();playContentService.playVideo(value,'play')" class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/card-play.svg">
                            <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.player_play_video || 'Play'}}</span>
                        </span>
                        <span class = "most-watched-action-card-tooltip" *ngIf="!value?.wishlist && !guest && !value.is_livechannel">
                            <img (click)="playContentService.addToWishlist(value)"  class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/wishlist_add.svg">
                            <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.home_pop_menu_wishlist_add || 'Add to wishlist'}}</span>
                        </span>
                        <span class = "most-watched-action-card-tooltip" *ngIf="value?.wishlist && !guest && !value.is_livechannel">
                            <img (click)="playContentService.removeWishlist(value)" class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/wishlist_remove.svg">
                            <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.remove_watchlist_definition || 'Remove from watchlist'}}</span>
                        </span>
                        <span class = "most-watched-action-card-tooltip">
                            <img (click)="redirect(value,data.key,data.title)" class="cursor-pointer most-watched-card-icon" src="assets/{{appName}}/img/content_info.svg">
                            <span class = "most-watched-action-card-tooltip-text">{{configService?.localeKeys?.see_info_title || 'See info'}}</span>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div ngxSlickItem class="slide-box tile video-box lower-z-index" *ngIf="(data.count > (data.take_value || takeValue)) && (data.count - (data.take_value || takeValue) > 0)">
            <div [id]="'view-all'+data.key+dataList.length" class="top-in-region-view-all-tile transition-class" (click)="viewMore(data)" (mouseover)="hoverIn(data.key, 'region', 'view-all', dataList.length+1, dataList.length)" (mouseout)="hoverOut(data.key, 'view-all', dataList.length)">
                <img loading="lazy" src="assets/{{appName}}/img/view-all-plus.svg" alt="" />
                <a class="txt-trunc"> {{configService?.localeKeys?.homepg_viewmore_card_title_part1 || 'View'}} {{data.count - (data.take_value || takeValue)}} {{configService?.localeKeys?.homepg_viewmore_card_title_part2 || 'more'}} </a>
            </div>
        </div>
    </ngx-slick-carousel>
</div>
