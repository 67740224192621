import { Component, HostListener, OnInit } from '@angular/core';
import { BaseService, SettingsService, StorageService } from '../services';
import { Router } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { CategoriesService } from '../services/categories.service';
import { ContentService } from '../services/content.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './' + environment.htmlAppName + '/footer.component.html',
  styleUrls: ['./' + environment.appName + '/footer.component.scss']
})
export class FooterComponent implements OnInit {

  selectedLocale: any;
  selectedLocaleKey: any;
  locales: any;
  localeLength: any;
  showLanguageTop = true;
  dropPosBottom = true;
  prevHeight: number;
  isMobile: boolean;
  innerWidth;
  appName;
  nl = false

  constructor(public configService:ConfigurationService, private storageService:StorageService,
              public settingsService: SettingsService, public categoryService: CategoriesService,
              public baseService: BaseService, private router: Router, private contentService: ContentService) {
    this.selectedLocaleKey = this.configService.locales[this.configService.localeIndex].lang_code;
    this.locales = this.configService.locales;
    this.localeLength = this.locales.length;
    if (this.localeLength <6) this.dropPosBottom = false;
    this.checkLangPostion();
    this.prevHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    this.isMobile = this.baseService.isMobileOrNot();
    this.appName = environment.appName;
    if(this.storageService.getLocalStore('locale').includes('nl')) this.nl = true;
  }

  async ngOnInit() {
    await this.contentService.translateSelectedLocale().then((selectedLocale) =>  {
      this.selectedLocale = selectedLocale;
    });
    this.locales = this.contentService.translateLanguage(this.locales);
  }

  setLocale(i, value) {
    this.selectedLocale = value.translatedLanguage;
    this.selectedLocaleKey = value.lang_code;
    this.configService.localeIndex = i;
    this.storageService.setLocalStore('locale', this.selectedLocaleKey);
    this.storageService.setLocalStore('localeName', this.selectedLocale);
    this.configService.setlocaleCookie(this.selectedLocaleKey);
    this.configService.locale = this.selectedLocaleKey;
    window.location.reload();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkLangPostion();
    this.innerWidth = window.innerWidth;
    this.isMobile = this.baseService.isMobileOrNot();
  }

  checkLangPostion(){
    if(window.innerWidth< 560) this.showLanguageTop = false;
    else this.showLanguageTop = true;
  }

  feedbackRedirect() {
    let feedbackURL = '/app/feedback';
    if(this.baseService.showAndroidApp || this.baseService.showIosApp) feedbackURL = '/account/m/feedback';
    this.router.navigateByUrl(feedbackURL)
  }

  redirect(value) {
    window.scrollTo({top: 0,behavior: 'smooth'});
    this.baseService.redirect(value);
  }

}
